const initialState = {
  loading: false,
  totalSupply: 0,
  isWlEnabled: false,
  isWlEnabled1: false,
  isWlEnabled2: false,
  isAlEnabled: false,
  mintable: 0,
  error: false,
  errorMsg: "",
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHECK_DATA_REQUEST":
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_SUCCESS":
      return {
        ...state,
        loading: false,
        totalSupply: action.payload.totalSupply,
        isWlEnabled1: action.payload.isWlEnabled1,
        isWlEnabled2: action.payload.isWlEnabled2,
        // isWlEnabled3: action.payload.isWlEnabled3,
        isAlEnabled: action.payload.isAlEnabled,
        // isPublicSaleEnabled: action.payload.isPublicSaleEnabled,
        mintable: action.payload.mintable,
        // cost: action.payload.cost,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_FAILED":
      return {
        ...initialState,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default dataReducer;
